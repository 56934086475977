import React, { useEffect, useState, Fragment } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useScrollYPosition } from 'react-use-scroll-position';

import { menuDataSource } from '../../common/data/navbar';
import { Brand } from '../../common/data/brand';

const ContainerWrapper = tw.section`
flex
 w-full 
 h-full 
 m-auto 
 box-border
 py-0
 px-6
 max-w-screen-xl
`;

const HeaderWrapper = styled.nav`
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  section {
    align-items: center;
    justify-content: space-between;
    height: 80px;
    transition: height 0.3s ease;
    .nav-m {
      &.open {
        button {
          width: 1.5rem;
          height: 2.5rem;
          div {
            ${tw`bg-primary-800`};
            &.second {
              opacity: 0;
            }
            &.first {
              top: 1.1rem;
              transform: rotate(45deg);
            }
            &.third {
              bottom: 1.1rem;
              transform: rotate(-45deg);
            }
          }
        }
      }
      button {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        background: transparent;
        border: none;
        div {
          position: absolute;
          width: 100%;
          height: 3px;
          background: black;
          transition: all 0.2s;
          &.first {
            top: 0;
            left: 0;
          }
          &.second {
            top: calc(50% - 1.5px);
            left: 0;
          }
          &.third {
            bottom: 0;
            left: 0;
          }
        }
      }
    }
    .nav-d {
      display: none;
      a {
        position: relative;
        z-index: 10;
        margin-left: 1.5rem;
        padding: 0.2rem 0.4rem;
        font-size: 1.4rem;
        line-height: 2.8rem;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease;
        ${tw`text-gray-700`}
        font-size: 1rem;
        &:hover {
          color: ${tw`text-primary-700`};
          font-weight: bold;
          text-decoration: underline;
          &::before {
            width: 100%;
            height: 100%;
            opacity: 1;
          }
        }
      }
      .divider {
        position: relative;
        margin-right: 2rem;
        &::after {
          position: absolute;
          top: -0.3rem;
          right: -2rem;
          width: 0.1rem;
          height: 3rem;
          background: linear-gradient(90deg, #ef412d, #4f2920);
          content: '';
        }
      }
    }
    .nav-mm {
      position: fixed;
      top: 80px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 80px);
      padding: 2rem;
      font-size: 1.4rem;
      line-height: 2.8rem;
      text-transform: uppercase;
      background: white;
      transform: translateX(${(props) => (props.open ? '0%' : '100%')});
      transition: transform 0.3s ease;
      @media (min-width: 600px) {
        padding: 3rem;
      }
      a {
        margin-bottom: 1rem;
        ${tw`text-primary-800`}
        &.divider {
          position: relative;
          margin-bottom: 6rem;
          &:after {
            position: absolute;
            bottom: -3rem;
            left: 0;
            width: 100%;
            height: 0.2rem;
            background: linear-gradient(90deg, #ef412d, #4f2920);
            content: '';
          }
        }
      }
    }
    @media (min-width: 900px) {
      .nav-m {
        display: none;
      }
      .nav-d {
        display: flex;
      }
      .nav-mm {
        display: none;
      }
    }
  }
`;

const HeaderMenuMask = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.triggered ? '100%' : '0%')};
  transition: 0.3s all;
  z-index: -1;
  height: 100%;
`;

const HeaderScrolledMask = styled.div`
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  transition: width 0.3s ease;
  z-index: -1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.triggered ? '100%' : '0%')};
`;

export const LogoLink = styled.a`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-12`}
  }
`;

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

export default function Header({ location }) {
  const [open, setOpen] = useState(false);

  // const scrollY = typeof window !== 'undefined' ? useScrollYPosition() : 0,
  const scrollY = useScrollYPosition();
  const scrolled = scrollY !== 0;

  useEffect(() => {
    setOpen(false);
  }, [location, scrollY]);
  const { buynow, getstarted ,appointment, pricing, help, taxchecklist } = menuDataSource;
  const defaultLinks = [
    <Fragment>
      {/* <Link to={taxcalulator.url} key={taxcalulator.id}>
        {taxcalulator.name}
      </Link> */}
      <Link to={buynow.url} key={buynow.id}>
        {buynow.name}
      </Link>
      <Link to={getstarted.url} key={getstarted.id}>
        {getstarted.name}
      </Link>
      <Link to={appointment.url} key={appointment.id}>
        {appointment.name}
      </Link>
      <AnchorLink to={pricing.url} key={pricing.id}>
        {pricing.name}
      </AnchorLink>
      
      <Link to={help.url} key={help.id}>
        {help.name}
      </Link>
      <Link to={taxchecklist.url} key={taxchecklist.id}>
        {taxchecklist.name}
      </Link>

      {/* <Link className="divider" to={signin.url} key={signin.id}>
        {signin.name}
      </Link>
      <Link to={signup.url} key={signup.id}>
        {signup.name}
      </Link> */}
    </Fragment>,
  ];
  const defaultLogoLink = (
    <LogoLink href="/" key={Brand.name}>
      <img src={Brand.logoLink} alt="logo" />
      {Brand.name}
    </LogoLink>
  );

  return (
    <HeaderWrapper triggered={scrolled} open={open}>
      <HeaderMenuMask triggered={open} />
      <HeaderScrolledMask triggered={scrolled} />
      <ContainerWrapper>
        {defaultLogoLink}
        <div className="nav-d" key="desktop">
          {defaultLinks}
        </div>
        <div className={`nav-m${open ? ' open' : ''}`}>
          <button aria-label="Toggle Menu" onClick={() => setOpen(!open)} className="icon">
            <div className="first" key="first" />
            <div className="second" key="second" />
            <div className="third" key="third" />
          </button>
        </div>
        <div className="nav-mm" key="mobile">
          {defaultLinks}
        </div>
      </ContainerWrapper>
    </HeaderWrapper>
  );
}

// export default function Header() {
//   return <>Header</>;
// }

// Header.propTypes = {
//   location: PropTypes.object.isRequired,
// };
