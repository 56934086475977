import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';

const Container = tw.div`text-center p-6 bg-gray-200`

const Copyright = () => {
    return ( 
     <Container>
    <span>©{new Date().getFullYear()} Copyright:</span>
    <Link tw="ml-2 text-gray-600 font-semibold" to='/'>
    Countmycents.com
    </Link>
     </Container>
    
     );
}
 
export default Copyright;