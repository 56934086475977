import React from 'react';
// import tw from 'twin.macro';
import styled from 'styled-components';

import GlobalStyles from './../styles/GlobalStyles';
import Header from './headers';
import Footer from './footer';
import './layout.css';

const Container = styled.div`
  min-height: 100vh;
  --tw-text-opacity: 1;
  color: rgba(36, 62, 99, var(--tw-text-opacity));
  padding: 2rem;
  overflow: hidden;
`;

const Layout = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <GlobalStyles />
      <Header />
      {children}
      <Footer />
    </Container>
  );
};

export default Layout;
