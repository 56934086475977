export const menuDataSource = {
  taxcalulator: { id: '1', name: 'Tax Calculator', url: '/tax-calculator' },
  buynow: {
    id: '1',
    name: 'Buy now',
    url: '/buy-now/',
  },
  getstarted: {
    id: '2',
    name: 'Get Started',
    url: '/get-started/',
  },
  appointment: {
    id: '3',
    name: 'Appointment',
    url: '/book-an-appointment/',
  },
  pricing: {
    id: '4',
    name: 'Pricing',
    url: '/#pricing',
  },
  help: {
    id: '5',
    name: 'Help',
    url: '/contact/',
  },
  taxchecklist: {
    id: '6',
    name: 'Tax Checklist',
    url: '/canada-tax-checklist/',
  },
  signin: {
    id: '7',
    name: 'Sign In',
    url: '/#',
  },
  signup: {
    id: '8',
    name: 'Sign Up',
    url: '/#',
  },
};
